<template>
  <div>
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok="onPrint"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-disabled="selectedLabelsTemplate === null"
      ok-title="Générer les étiquettes"
      @show="onShow"
    >
    <template v-slot:modal-title>
      <b><i class="fas fa-print"></i> Imprimer des étiquettes</b>
    </template>
    <div>
      <div v-if="errorText" class="error-text">
        {{ errorText }}
      </div>
      <b-form-group
        label-for="labelsTemplate"
        label="Modèle d'étiquettes"
        :description="labelsTemplateDescription"
      >
        <b-form-select
          name="labelsTemplate"
          v-model="selectedLabelsTemplate"
          required
          :disabled="labelsTemplates.length === 0"
        >
          <b-select-option :value="null"></b-select-option>
          <b-select-option v-for="elt of labelsTemplates" :key="elt.id" :value="elt">
            {{ elt.name }}
          </b-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label-for="labelEmpty" label="Nombre d'étiquettes à laisser vides"
        description="Nombre d'étiquettes manquantes en haut à gauche de votre première page"
      >
        <b-form-input name="labelEmpty" type="number" v-model="emptyLabels" required min="0"></b-form-input>
      </b-form-group>
    </div>
    <div v-if="(selectedLabelsTemplate === null) && hasPerm('home.add_labelstemplate')">
      <a href @click.prevent="addNewLabelsTemplate"><i class="fa fa-add"></i> Créer un nouveau gabarit d'étiquettes</a>
    </div>
    <div v-if="(selectedLabelsTemplate !== null) && hasPerm('home.change_labelstemplate')">
      <a href @click.prevent="editLabelsTemplate"><i class="fa fa-edit"></i> Modifier le gabarit d'étiquettes</a>
    </div>
    </b-modal>
    <edit-labels-template-modal
      modal-id="edit-labels-template-modal"
      :obj="selectedLabelsTemplate"
      @saved="loadLabelsTemplates()"
    >
    </edit-labels-template-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EditLabelsTemplateModal from './EditLabelsTemplateModal'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { makeLabelsTemplate } from '@/types/contacts'

export default {
  name: 'PrintLabelsModal',
  components: {
    EditLabelsTemplateModal,
  },
  mixins: [BackendMixin],
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      labelsTemplates: [],
      selectedLabelsTemplate: null,
      emptyLabels: 0,
      errorText: '',
    }
  },
  computed: {
    labelsTemplateDescription() {
      if (this.labelsTemplates.length === 0) {
        if (this.hasPerm('contact.add_labelstemplate')) {
          return 'Vous devez créer un nouveau gabarit d\'étiquettes'
        } else {
          return 'Contactez un responsable pour la création d\'un nouveau gabarit d\'étiquettes'
        }
      }
      return 'Sélectionnez le gabarit d\'étiquettes souhaité'
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async onShow() {
      this.errorText = ''
      this.addMode = false
      this.$bvModal.show(this.modalId)
      await this.loadLabelsTemplates()
      if (this.labelsTemplates.length === 0 && this.hasPerm('contact.add_labelstemplate')) {
        this.addNewLabelsTemplate()
      }
    },
    async loadLabelsTemplates() {
      this.errorText = ''
      const backend = new BackendApi('get', '/api/home/labels-templates/')
      try {
        const resp = await backend.callApi()
        this.labelsTemplates = resp.data.map(makeLabelsTemplate)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    onPrint() {
      if (this.selectedLabelsTemplate !== null) {
        localStorage.setItem('lastSelectedLabelTemplate', '' + this.selectedLabelsTemplate.id)
        this.$emit(
          'save',
          { labelsTemplate: this.selectedLabelsTemplate, emptyLabels: +this.emptyLabels, }
        )
      }
    },
    addNewLabelsTemplate() {
      this.$bvModal.show('edit-labels-template-modal')
    },
    editLabelsTemplate() {
      this.$bvModal.show('edit-labels-template-modal')
    },
  },
}
</script>
